<template>
  <div>
    <!-- Choose Licences Modal -->
    <b-modal
      id="modal-licences"
      ref="modal-licences"
      centered
      hide-footer
      hide-header
      hide-header-close
      no-fade
    >
      <h4 class="mb-3">
        Add Licences
      </h4>
      <span class="add-licences-subtitle">
        Choose the total number of paying licences for your account.
      </span>
      <span class="add-licences-subtitle">
        You will need one licence for each Surfe user.
      </span>

      <div style="margin-top: 48px; margin-bottom: 18px">
        <b-form-spinbutton
          v-model="licences"
          min="0"
          max="10000"
          class="my-3 spinbutton"
        />
      </div>

      <div>
        <span>{{ currencySymbol }}{{ price * licences }}</span>
        <p class="add-licences-subtitle">
          {{ currencySymbol }}{{ price }} x {{ licences }} licences for {{ period === 'yearly' ? '12 months' : '1 month' }}
        </p>
      </div>

      <div class="d-flex justify-content-end position-absolute buttons-pos">
        <b-button
          variant="white"
          class="mr-2 cancel-button"
          style="min-width: 150px;"
          @click="closeModal('modal-licences')"
        >
          <span>Go back</span>
        </b-button>
        <b-button
          class="submit-button"
          variant="primary"
          @click="showModalByName('modal-checkout-details')"
        >
          <span>Next: Billing Information</span>
        </b-button>
      </div>
    </b-modal>

    <!-- Billing details Modal -->
    <b-modal
      id="modal-checkout-details"
      ref="modal-checkout-details"
      centered
      hide-footer
      hide-header
      hide-header-close
      no-fade
    >
      <h3>Check your billing details</h3>
      <p class="checkout-details-subtitle">
        Your billing details will appear on your invoices.
      </p>

      <BillingDetails
        ref="billing"
        :show-coupon="true"
      />

      <div class="d-flex justify-content-end position-absolute buttons-pos">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="white"
          class="mr-2 cancel-button"
          @click="closeModal('modal-checkout-details')"
        >
          <span>Go back</span>
        </b-button>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          :disabled="waitForBillingDetails"
          @click="saveBillingDetails"
        >
          <b-spinner
            v-if="waitForBillingDetails"
            class="mx-2"
            small
          />
          <span v-else>
            Next: Payment Information
          </span>
        </b-button>
      </div>
    </b-modal>

    <!-- Payment Method Modal -->
    <b-modal
      id="modal-checkout-pay"
      ref="modal-checkout-pay"
      centered
      hide-footer
      hide-header
      hide-header-close
      no-fade
    >
      <div id="payment-method-selection">
        <h3>Select a payment method</h3>
        <p class="checkout-details-subtitle">
          Please choose a payment method to start enjoying our features.
        </p>

        <div
          id="payment-method-banner"
          class="d-flex justify-content-between align-items-center position-relative"
        >
          <img
            :src="getPaymentPlanImage()"
            :alt="`${plan}-plan`"
            style="width: 102px"
          >
          <div
            class="d-flex flex-column "
          >
            <div
              v-if="quote.couponPercentOff"
              id="coupon-box"
            >
              {{ quote.couponPercentOff }}% off
            </div>
            <div>
              <p class="font-weight-bolder m-0">
                {{ capitalizeFirstLetter(plan) }} plan
              </p>
              <p
                style="color: #889BA0;font-size: 12px"
                class="m-0"
              >
                Total licences: {{ licences }}
              </p>
            </div>
          </div>
          <span
            v-if="waitForQuote"
            style="text-align:end"
          >
            <b-spinner
              variant="primary"
              small
            />
          </span>
          <div>
            <sup
              class="font-medium-1 font-weight-bold"
              style="top: -1em; color: #889BA0"
            >
              {{ currencySymbol }}
            </sup>
            <span
              class="font-weight-bold text-primary"
              style="font-size: 2.25em"
            >
              {{ roundNumber(quote.total) }}
            </span>
            <span
              style="color: #889BA0"
              class="pricing-duration"
            >
              / {{ period === "monthly" ? "month" : "year" }}
            </span>
            <div
              v-if="quote.vatPercent > 0"
              class="ml-6"
            >
              <span
                style="color: #889BA0; font-size: 12px"
              >
                VAT ({{ quote.vatPercent }}%) {{ roundNumber(quote.vatTotal) }}
              </span>
              <span style="color: #5A6C71; font-size: 12px">
                {{ currencySymbol }} {{ roundNumber(quote.subTotal) }}
              </span>
            </div>
          </div>
        </div>

        <h5 style="margin-top: 38px; margin-bottom: 15px">
          Select payment method
        </h5>
        <PaymentMethodSelection
          :plan="plan"
          :period="period"
          :licences="licences"
          :currency="currency"
          :currency-symbol="currencySymbol"
          :namespace="namespace"
          :tiered="tiered"
          :stripe="stripe"
          @payment-method-change="handlePaymentMethodChange"
          @event-change="e => eventComplete = e"
        />
        <div class="d-flex justify-content-end mt-10 position-absolute buttons-pos">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="white"
            class="mr-2 cancel-button"
            @click="closeModal('modal-checkout-pay')"
          >
            <span>Go back</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            :disabled="waitForPayment || waitForQuote || !eventComplete"
            @click="pay"
          >
            <b-spinner
              v-if="waitForPayment"
              class="mx-2"
              small
            />
            <span v-else>Pay now</span>
          </b-button>
        </div>
      </div>
    </b-modal>

    <AssignSeatsModal />
  </div>
</template>

<script>
import {
  BModal, BButton, BSpinner, BFormSpinbutton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { mapState } from 'vuex'
import essential from '@/assets/images/payment-plans/essential.png'
import pro from '@/assets/images/payment-plans/pro.png'
import free from '@/assets/images/payment-plans/free.png'
import AssignSeatsModal from '@/views/billing/AssignSeatsModal.vue'
import { trackEvent } from '@core/utils/tracking'
import BillingDetails from './BillingDetails.vue'
import PaymentMethodSelection from './PaymentMethodSelection.vue'

const { authHeaders } = require('../../../utils')

// eslint-disable-next-line no-undef
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)

export default {
  components: {
    AssignSeatsModal,
    BModal,
    BButton,
    BSpinner,
    BFormSpinbutton,
    BillingDetails,
    PaymentMethodSelection,
  },
  directives: {
    Ripple,
  },
  props: {
    plan: {
      type: String,
      default: '',
    },
    period: {
      type: String,
      default: '',
    },
    namespace: {
      type: String,
      default: '',
    },
    tiered: {
      type: Boolean,
      default: false,
    },
    price: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    currencySymbol: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      stripe,
      quote: {},
      licences: 1,
      waitForBillingDetails: false,
      waitForQuote: false,
      waitForPayment: false,
      eventComplete: false,
      paymentMethod: {},
      modalsOrder: {
        'modal-licences': { name: 'modal-licences', prev: undefined },
        'modal-checkout-details': { name: 'modal-checkout-details', prev: 'modal-licences' },
        'modal-checkout-pay': { name: undefined, prev: 'modal-checkout-details' },
      },
    }
  },
  computed: {
    ...mapState({
      customer: state => state.api.customer,
      account: state => state.api.account,
    }),
  },
  async mounted() {
    await this.computeQuote()
  },
  methods: {
    showModalByName(name) {
      this.$refs[name].show()

      const modalToClose = this.modalsOrder[name].prev
      if (!modalToClose) return

      this.$refs[this.modalsOrder[modalToClose].name].hide()
    },
    closeModal(name) {
      this.$refs[name].hide()

      const modalToOpen = this.modalsOrder[name].prev
      if (!modalToOpen) return

      this.$refs[this.modalsOrder[modalToOpen].name].show()
    },
    roundNumber(num) {
      if (typeof num !== 'number') return ''
      return Math.round(num * 100) / 100
    },
    capitalizeFirstLetter(val) {
      if (!val.length) return val
      return val[0].toUpperCase() + val.substring(1)
    },
    async saveBillingDetails() {
      this.waitForBillingDetails = true
      try {
        await this.$refs.billing.update()
        await this.computeQuote()
        this.$refs['modal-checkout-details'].hide()
        this.$refs['modal-checkout-pay'].show()
      } catch (error) {
        console.error(error)
      }
      this.waitForBillingDetails = false
    },
    async createSubscription(id, pCountry, type) {
      let country = (this.customer.address || {}).country || 'US'
      if (country) country = pCountry

      const { data } = await axios.post(
        `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/subscription`,
        {
          Plan: this.plan,
          Frequency: this.period,
          Currency: this.currency,
          Quantity: parseInt(this.licences),
          Country: country,
          PaymentMethodID: id,
          PaymentMethod: type,
          Namespace: 'surfe',
          Tiered: false,
        },
        authHeaders(),
      )
      return data
    },
    async computeQuote() {
      this.waitForQuote = true

      // Country
      let country = (this.customer.address || {}).country || 'US'
      if ((this.paymentMethod || {}).card && this.paymentMethod.card.country) {
        country = this.paymentMethod.card.country
      }
      if ((this.paymentMethod || {}).sepa_debit && this.paymentMethod.sepa_debit.country) {
        country = this.paymentMethod.sepa_debit.country
      }

      // Retrieve quote information
      const { data } = await axios.post(
        `${process.env.VUE_APP_CORE_API_URL}/admin/checkout/quote`,
        {
          Plan: this.plan,
          Frequency: this.period,
          Currency: this.currency,
          Quantity: parseInt(this.licences),
          Country: country,
          Namespace: this.namespace,
          Tiered: this.tiered,
        },
        authHeaders(),
      )
      this.quote = data

      this.quote.subTotal = this.licences * this.quote.unitCost * (1.0 - this.quote.couponPercentOff / 100.0)
      this.quote.vatTotal = this.quote.subTotal * (this.quote.vatPercent / 100.0)
      this.quote.total = this.quote.subTotal + this.quote.vatTotal
      this.waitForQuote = false
    },
    async createCardPaymentMethod(card) {
      const result = await this.stripe.createPaymentMethod({
        type: 'card',
        card,
        billing_details: {
          name: this.customer.name || this.account.name,
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async createSepaPaymentMethod(sepa) {
      const result = await this.stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: sepa,
        billing_details: {
          name: this.customer.name || this.account.name,
          email: this.customer.email || this.account.email, // email is required for SEPA transfers
        },
      })
      if (result.error) throw result.error
      return result.paymentMethod
    },
    async handlePaymentMethodChange({ method, value }) {
      switch (method) {
        case 'card':
          this.paymentMethod = await this.createCardPaymentMethod(value)
          await this.computeQuote()
          break
        case 'sepa':
          this.paymentMethod = await this.createSepaPaymentMethod(value)
          await this.computeQuote()
          break
        default:
          break
      }
    },
    async pay() {
      this.waitForPayment = true

      try {
        const country = this.paymentMethod?.card?.country ?? this.paymentMethod?.sepa_debit?.country
        const subscription = await this.createSubscription(this.paymentMethod.id, country, this.paymentMethod.type)

        // Some payment methods require a customer to do additional
        // authentication with their financial institution. Eg: 2FA for cards.
        if (!subscription || subscription.status !== 'active') {
          if (this.paymentMethod.type === 'card') await this.handleCardPaymentThatRequiresCustomerAction(subscription, this.paymentMethod.id)
          else await this.handleSepaPaymentThatRequiresCustomerAction(subscription, this.paymentMethod.id)
        }

        await trackEvent({
          type: 'dashboard-billing-confirmed',
          props: { plan: this.plan?.toUpperCase(), count: this.licences, isYearly: this.period === 'yearly' },
        })
        await trackEvent({ type: 'billing-screen_4-checked_out' })
        setTimeout(async () => {
          this.$swal({
            title: 'Welcome on board!',
            text: 'Your payment was successfully processed.',
            icon: 'success',
            confirmButtonText: 'Assign Licences',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(async result => {
            if (result.isConfirmed) {
              await this.$store.dispatch('appState/updateSelectedLicences', this.licences)
              await this.$bvModal.show('assign-seats-modal')
            }
          })
        }, 1000)
      } catch (err) {
        let msg = err
        if (err.response && err.response.data && err.response.data.message) {
          msg = err.response.data.message
        } else if (err.message) {
          msg = err.message
        } else if (err.error && err.error.message) {
          msg = err.error.message
        } else {
          console.error(`[Stripe] ${JSON.stringify(err)}`)
        }

        this.$refs['modal-checkout-pay'].hide()
        this.$swal({
          title: 'Failed to complete payment',
          text: msg,
          icon: 'error',
        })
        await trackEvent({ type: 'billing-screen_4-error_viewed' })
        this.waitForPayment = false
      }
    },
    async handleCardPaymentThatRequiresCustomerAction(subscription, paymentMethodId) {
      const paymentIntent = subscription.latest_invoice.payment_intent
      if (paymentIntent.status === 'requires_action' || paymentIntent.status === 'requires_payment_method') {
        const result = await this.stripe.confirmCardPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        if (result.error) throw result
      }
    },
    async handleSepaPaymentThatRequiresCustomerAction(subscription, paymentMethodId) {
      const paymentIntent = subscription.latest_invoice.payment_intent
      if (paymentIntent.status === 'requires_action'
          || paymentIntent.status === 'requires_payment_method'
          || paymentIntent.status === 'requires_confirmation') {
        const result = await this.stripe.confirmSepaDebitPayment(paymentIntent.client_secret, {
          payment_method: paymentMethodId,
        })
        if (result.error) throw result
      }
    },
    getPaymentPlanImage() {
      switch (this.plan) {
        case 'pro':
          return pro
        case 'free':
          return free
        case 'essential':
          return essential
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss">
  #modal-checkout-details > div {
    max-width: 555px;
    height: 593px;

    &> div {
      height: 610px;
    }
  }

  #modal-checkout-pay > div{
    position: relative;
    max-width: 555px;

    &> div {
      height: 610px;
    }
  }

  .checkout-details-subtitle {
    color: #889BA0;
    font-size: 14px;
    margin-bottom: 35px;
  }

  .buttons-pos{
    bottom: 30px;
    right: 30px;
  }

  .cancel-button {
    color:#5C818A
  }

  .payment-method-selection {
    min-height: 558px;
  }

  #payment-method-banner {
    width: 100%;
    height: 107px;
    border-radius: 4px;
    border: 1.2px solid #73C7E1;
    background-color: #EFFBFE;
    padding: 16px;
  }

  #coupon-box {
    position: absolute;
    top: 13px;
    padding: 2px 6px;
    background-color: #FFDEF7;
    border-radius: 4px;
    color: #EA75CF;
    font-size: 12px;
    height: 18px;
  }

  .add-licences-subtitle {
    color: #5A6C71;
    font-size: 14px;
    margin-bottom:49px;
  }
</style>
