<template>
  <b-modal
    id="modal-invite"
    ref="modal-invite"
    title="Invite a new team member"
    centered
    hide-footer
    @ok="sendInvite"
  >
    <div class="mb-2 font-small-4">
      We will send an invitation to join the team to the following email:
    </div>

    <b-form-input
      v-model="email"
      placeholder="Email"
      type="email"
      name="email"
    />

    <b-form-checkbox
      v-model="isAdmin"
      checked="false"
      name="check-button"
      switch
      class="mt-4 mb-8"
    >
      Admin only access (accountant, CFO, ...)
      <feather-icon
        v-b-tooltip.hover.top="'This person won\'t be using Surfe but will benefit from an admin access to this dahsboard.'"
        variant="outline-primary"
        size="14"
        icon="InfoIcon"
        class="text-muted"
      />
    </b-form-checkbox>
    <b-button
      style="float:right"
      :variant="'primary'"
      :disabled="waitForInvite"
      @click="sendInvite()"
    >
      <b-spinner
        v-if="waitForInvite"
        class="mx-2"
        small
      />
      <span v-else>
        Invite
      </span>
    </b-button>
    <b-button
      style="float:right"
      variant="outline"
      :disabled="waitForInvite"
      @click="closeModal()"
    >
      Go back
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BFormInput, BFormCheckbox, VBTooltip, BButton, BSpinner,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'

export default {
  components: {
    BSpinner,
    BButton,
    BModal,
    BFormInput,
    BFormCheckbox,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      waitForInvite: false,
      email: '',
      isAdmin: false,
    }
  },
  methods: {
    closeModal() {
      this.$bvModal.hide('modal-invite')
    },
    async sendInvite() {
      try {
        this.waitForInvite = true
        await this.$store.dispatch('api/inviteUsers', [{
          email: this.email, isAdmin: this.isAdmin,
        }])
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invitation sent successfully',
            icon: 'SendIcon',
            variant: 'success',
          },
        })
        await trackEvent({ type: 'dashboard-team-invited', props: { direct: false, isAdminOnly: this.isAdmin } })
        this.email = ''
        this.isAdmin = false
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'An error occurred',
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
        throw error
      } finally {
        this.waitForInvite = false
        this.closeModal()
      }
    },
  },
}
</script>

<style scoped>
.subtitle{
  color: #5A6C71;
  font-size: 14px;
}
</style>
