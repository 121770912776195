<template>
  <!-- Accordion Menu -->
  <div role="tablist">
    <b-card
      no-body
      style="border-bottom: 1px solid #E0E5E6;"
      class="mb-1 accordion"
    >
      <b-card-header
        v-b-toggle.sepa-accordion
        header-tag="header"
        class="p-1"
        role="tab"
        @click="toggleCheckbox('sepa')"
      >
        <div class="d-flex p-2">
          <checkbox
            :checked="checked.sepa"
            label="SEPA Payment"
            :disabled="true"
          />

        </div>
        <span>
          <feather-icon
            v-if="checked.sepa"
            icon="ChevronUpIcon"
            size="15"
          />
          <feather-icon
            v-else
            icon="ChevronDownIcon"
            size="15"
          />
        </span>
      </b-card-header>
      <b-collapse
        id="sepa-accordion"
        :visible="isEurope"
        accordion="checkout-accordion"
        role="tabpanel"
      >
        <b-card-body class="pt-0">
          <b-card-text>
            <img
              class="mb-1"
              src="@/assets/images/powered_stripe.png"
              style="width: 90px"
              alt="powered_stripe"
            >
            <div id="sepa-element" />
            <div id="sepa-element-errors" />
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>

    <b-card
      no-body
      class="mb-0 accordion"
    >
      <b-card-header
        v-b-toggle.card-accordion
        header-tag="header"
        class="p-1"
        role="tab"
        @click="toggleCheckbox('card')"
      >
        <div class="d-flex p-2">
          <checkbox
            :checked="checked.card"
            label="Credit / Debit Card"
            :disabled="true"
          />
        </div>
        <span>
          <feather-icon
            v-if="checked.card"
            icon="ChevronUpIcon"
            size="15"
          />
          <feather-icon
            v-else
            icon="ChevronDownIcon"
            size="15"
          />
        </span>
      </b-card-header>
      <b-collapse
        id="card-accordion"
        :visible="!isEurope"
        accordion="checkout-accordion"
        role="tabpanel"
      >
        <b-card-body class="pt-0">
          <b-card-text>
            <img
              class="mb-1"
              src="@/assets/images/powered_stripe.png"
              style="width: 90px"
              alt="powered_stripe"
            >
            <div id="card-element" />
            <div id="card-element-errors" />
          </b-card-text>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCollapse, BCardBody, BCardText, BCardHeader,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Checkbox from '@core/components/checkbox/Checkbox.vue'
import { trackEvent } from '@core/utils/tracking'

const { sleep } = require('../../../utils')

export default {
  name: 'PaymentMethodSelection',
  components: {
    Checkbox,
    BCollapse,
    BCard,
    BCardText,
    BCardBody,
    BCardHeader,
  },
  directives: {
    Ripple,
  },
  props: {
    stripe: {
      type: Object,
      required: true,
    },
    plan: {
      type: String,
      default: '',
    },
    period: {
      type: String,
      default: '',
    },
    licences: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    namespace: {
      type: String,
      default: '',
    },
    tiered: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const isEurope = (Intl.DateTimeFormat().resolvedOptions().timeZone)?.toLowerCase()?.includes('europe')
    return {
      card: null,
      sepa: null,
      isEurope,
      checked: {
        sepa: isEurope,
        card: !isEurope,
      },
    }
  },
  async mounted() {
    this.createCheckoutForm()
  },
  methods: {
    async toggleCheckbox(paymentType) {
      this.checked = {
        sepa: false,
        card: false,
        [paymentType]: !this.checked[paymentType],
      }

      await trackEvent({ type: 'billing-screen_4-method_selected', props: { method: paymentType.toUpperCase() } })

      // Disable save button, because the user selected a different payment method
      this.$emit('event-change', false)

      // Reset current payment method to empty, if the user selected a different payment method
      if (paymentType === 'sepa') {
        this.sepa.update({ value: '' })
        return
      }
      this.card.update({ value: '' })
    },
    async createCheckoutForm() {
      // Wait for card element
      for (let i = 0; i < 15; i++) {
        await sleep(100)
        if (document.getElementById('card-element')) break
      }
      if (!document.getElementById('card-element')) return

      const elements = this.stripe.elements()

      // Card Element styles
      const style = {
        base: {
          fontSize: '16px',
          color: '#32325d',
          fontFamily: '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
          fontSmoothing: 'antialiased',
          '::placeholder': {
            color: '#a0aec0',
          },
        },
      }

      // Populate card section with stripe elements
      this.card = elements.create('card', { style })
      this.card.mount('#card-element')

      this.card.on('focus', () => {
        const el = document.getElementById('card-element-errors')
        el.classList.add('focused')
      })

      this.card.on('blur', () => {
        const el = document.getElementById('card-element-errors')
        el.classList.remove('focused')
      })

      this.card.on('change', async event => {
        const displayError = document.getElementById('card-element-errors')
        displayError.textContent = ''
        if (event.error) {
          displayError.textContent = event.error.message
          return
        }

        // Compute quote after card completion
        if (event.complete) {
          this.$emit('payment-method-change', { method: 'card', value: this.card })
        }
        this.$emit('event-change', event.complete)
      })

      // Populate SEPA section with stripe elements
      this.sepa = elements.create('iban', { style, supportedCountries: ['SEPA'] })
      this.sepa.mount('#sepa-element')

      this.sepa.on('change', async event => {
        const displayError = document.getElementById('sepa-element-errors')
        displayError.textContent = ''
        if (event.error) {
          displayError.textContent = event.error.message
          return
        }

        // Compute quote after card completion
        if (event.complete) {
          this.$emit('payment-method-change', { method: 'sepa', value: this.sepa })
        }
        this.$emit('event-change', event.complete)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .accordion {
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding-bottom: 0;
  }
</style>
