<template>
  <div>
    <div class="text-center mb-16">
      <h1>
        Pricing Plans
      </h1>

      <p class="mb-1 pb-75">
        Choose the best plan to fit your needs.
      </p>
    </div>

    <Plans
      :plan="plan"
      :period="plan.period"
      :currency="plan.currency"
      :currency-symbol="plan.currencySymbol"
      :namespace="plan.namespace"
      :tiered="plan.tiered"
      :show-buttons="true"
      :show-currency-switch="false"
    />

    <p class="text-center mt-4">
      <b-button
        variant="flat-primary"
        to="/billing"
      >
        <feather-icon
          icon="ArrowLeftIcon"
          size="16"
        />
        Back to Billing
      </b-button>
    </p>

    <div
      v-if="plan.namespace !== 'surfe'"
      style="max-width:800px;margin:0 auto"
      class="text-center"
    >
      <b-alert
        variant="primary"
        show
      >
        <div class="alert-body">
          Your current plan is discontinued, you'll switch to our new pricing
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import { BButton, BAlert } from 'bootstrap-vue'
import { mapState } from 'vuex'
import Plans from './components/Plans.vue'

export default {
  components: { BButton, BAlert, Plans },
  computed: {
    ...mapState({
      customer: state => state.api.customer,
      subscription: state => ((state.api.customer.subscriptions || {}).data || [{}])[0] || {},
      namespace: state => state.api.account.namespace,
      tiered: state => state.api.account.tiered,
    }),
    plan() {
      if (!this.subscription.plan) return {}

      let selected = 'basic'
      if (this.namespace === 'surfe') {
        if (this.subscription.plan.id.includes('basic')) selected = 'basic'
        else if (this.subscription.plan.id.includes('professional')) selected = 'professional'
        else if (this.subscription.plan.id.includes('pro_')) selected = 'pro'
        else if (this.subscription.plan.id.includes('essential')) selected = 'essential'
        else if (this.subscription.plan.id.includes('business')) selected = 'business'
      } else if (this.namespace === 'leadjet') {
        if (this.subscription.plan.id.includes('enrich')) selected = 'enrich'
        else if (this.subscription.plan.id.includes('professional')) selected = 'professional'
        else if (this.subscription.plan.id.includes('entreprise')) selected = 'entreprise'
      }

      return {
        selected,
        period: this.subscription.plan.id.includes('monthly') ? 'monthly' : 'yearly',
        licences: this.subscription.quantity,
        currency: this.customer.currency,
        currencySymbol: this.customer.currency === 'eur' ? '€' : '$',
        price: 0, // Ignored
        namespace: this.namespace,
        tiered: this.tiered,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
