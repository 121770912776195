<template>
  <b-form @submit.prevent>
    <b-row>
      <!-- Name -->
      <b-col cols="6">
        <b-form-group
          label="Account Name"
        >
          <b-form-input
            v-model="customer.name"
            placeholder="Account Name"
          />
        </b-form-group>
      </b-col>

      <!-- Email -->
      <b-col cols="6">
        <b-form-group
          label="Email"
        >
          <b-form-input
            v-model="customer.email"
            placeholder="Email"
          />
        </b-form-group>
      </b-col>

      <!-- Address -->
      <b-col cols="6">
        <b-form-group
          label="Address"
        >
          <b-form-input
            v-model="customer.address.line1"
            placeholder="Address"
          />
        </b-form-group>
      </b-col>

      <!-- Country -->
      <b-col cols="6">
        <b-form-group
          label="Country"
        >
          <v-select
            :value="customer.address.country"
            :clearable="false"
            :options="countries"
            @input="updateCountry"
          />
        </b-form-group>
      </b-col>

      <!-- City -->
      <b-col cols="6">
        <b-form-group
          label="City"
        >
          <b-form-input
            v-model="customer.address.city"
            placeholder="City"
          />
        </b-form-group>
      </b-col>

      <!-- Postal Code -->
      <b-col cols="6">
        <b-form-group
          label="Postal Code"
        >
          <b-form-input
            v-model="customer.address.postal_code"
            placeholder="Postal Code"
          />
        </b-form-group>
      </b-col>

      <!-- VAT Number -->
      <b-col cols="6">
        <b-form-group
          label="VAT Number"
        >
          <b-form-input
            v-model="vatNumber"
            placeholder="VAT Number (EU customers)"
            :state="vatOK"
          />
          <b-form-invalid-feedback>
            Invalid VAT number
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <!-- Coupon -->
      <b-col
        v-if="showCoupon"
        cols="6"
      >
        <b-form-group
          label="Coupon"
          valid-feedback="Valid coupon"
        >
          <b-form-input
            v-model="coupon"
            placeholder="Coupon"
            :state="couponOK"
          />
          <b-form-invalid-feedback>
            Invalid Coupon
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import {
  BCol, BRow, BForm, BFormGroup, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import countries from '@/assets/countries'
import vSelect from 'vue-select'

export default {
  components: {
    BCol, BRow, BForm, BFormGroup, BFormInput, vSelect, BFormInvalidFeedback,
  },
  props: ['showCoupon'],
  data() {
    return {
      countries,
      vatNumber: '',
      coupon: '',
      couponOK: null,
      vatOK: null,
    }
  },
  computed: {
    ...mapState({
      customer(state) {
        this.vatNumber = ((state.api.customer.tax_ids || {}).data || {}).length > 0 ? state.api.customer.tax_ids.data[0].value : ''
        return state.api.customer
      },
    }),
  },
  methods: {
    async update() {
      if (this.coupon) {
        await this.applyCoupon()
        if (!this.couponOK) throw Error(`'${this.coupon}' coupon is invalid.`)
      }
      await Promise.all([this.applyVAT(), this.updateInfo()])
      return this.$store.dispatch('api/loadCustomer')
    },
    async applyVAT() {
      let { vatNumber } = this
      if (!this.vatNumber) vatNumber = '-'

      try {
        await this.$store.dispatch('api/applyVAT', vatNumber)
      } catch (error) {
        this.vatOK = false
        throw error
      }
    },
    async applyCoupon() {
      try {
        const isValid = await this.$store.dispatch('api/applyCoupon', this.coupon)
        this.couponOK = isValid
      } catch (error) {
        this.couponOK = false
        throw error
      }
    },
    updateInfo() {
      return this.$store.dispatch('api/updateCustomer', this.customer)
    },
    updateCountry(val) {
      this.customer.address.country = val.value
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

legend {
  font-size:0.8em !important;
  color: #888;
}
</style>
