var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"pricing-card"},[(_vm.showCurrencySwitch)?_c('b-col',{staticClass:"mx-auto mb-3",class:['col-sm-12', _vm.windowWidth > 1655 ? 'col-lg-10' : 'col-lg-12']},[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('span',{staticClass:"mr-2"},[_vm._v("Prices in")]),_c('b-form-select',{staticStyle:{"text-transform":"uppercase","max-width":"80px"},attrs:{"options":['eur', 'usd'],"size":"sm"},model:{value:(_vm.currency),callback:function ($$v) {_vm.currency=$$v},expression:"currency"}})],1)]):_vm._e(),_c('b-col',{class:['mx-auto', _vm.windowWidth > 1655 ? 'col-lg-10' : 'col-lg-12']},[_c('b-row',{staticClass:"match-height d-flex justify-content-center"},[_c('b-col',{attrs:{"lg":"4","md":"8"}},[(_vm.account.crm !== 'GSHEETS')?_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"licences":_vm.licences,"plan-to-select":"free","features":[{text: 'Embed your CRM in LinkedIn'},
                      {text: 'Add <b>1</b> contact to CRM /day'},
                      {text: '<b>20</b> email finder credits /month'},
                      {text: '<b>5</b> phone finder credits /month'},
                      {text: '<b>30</b> SalesNav export credits', tooltip: 'Your company must own at least one Sales Navigator licence to use this feature'},
                      {text: '<b>1</b> Message template'},
                      {text: 'Deals, Tasks, Notes on LinkedIn'},
                      {text: '<b>10</b> message sync per month'} ],"is-loading":_vm.isLoading},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}}):_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"licences":_vm.licences,"plan-to-select":"free","features":[{text: 'Custom extra fields on LinkedIn'},
                      {text: 'Add <b>1</b> contact to CRM /day'},
                      {text: '<b>20</b> email finder credits /month'},
                      {text: '<b>5</b> phone finder credits /month'},
                      {text: '<b>30</b> SalesNav export credits', tooltip: 'Your company must own at least one Sales Navigator licence to use this feature'},
                      {text: '<b>1</b> Message template'} ],"is-loading":_vm.isLoading},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}})],1),_c('b-col',{attrs:{"lg":"4","md":"8"}},[(_vm.account.crm !== 'GSHEETS')?_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"plan-to-select":"essential","features":[{text: 'All from Free'},
                      {text: 'Add <b>unlimited</b> contacts to CRM'},
                      {text: '<b>150</b> email finder credits /month'},
                      {text: '<b>50</b> mobile finder credits /month'},
                      {text: '<b>100</b> SalesNav export credits /day'},
                      {text: '<b>Unlimited</b> message templates'},
                      {text: 'Sync <b>unlimited</b> LI messages to CRM'},
                      {text: 'Sales enablement', tooltip: 'Salesloft and Outreach integrations support'}],"is-loading":_vm.isLoading},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}}):_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"plan-to-select":"essential","features":[{text: 'All from Free'},
                      {text: 'Add <b>unlimited</b> Linkedin contacts'},
                      {text: '<b>150</b> email finder credits /month'},
                      {text: '<b>50</b> mobile finder credits /month'},
                      {text: '<b>100</b> SalesNav export credits /day'},
                      {text: '<b>Unlimited</b> message templates'}],"is-loading":_vm.isLoading},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}})],1),_c('b-col',{attrs:{"lg":"4","md":"8"}},[(_vm.account.crm !== 'GSHEETS')?_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"plan-to-select":"pro","is-loading":_vm.isLoading,"features":[{text: 'All from Essential'},
                      {text: '<b>Unlimited</b> email finder credits'},
                      {text: '<b>100</b> mobile finder credits /month'},
                      {text: '<b>200</b> SalesNav export credits /day'},
                      {text: 'Job change alerts'},
                      {text: 'Dashboard Analytics'}]},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}}):_c('PlanCard',{attrs:{"plan":_vm.plan,"period":_vm.period,"currency":_vm.currency,"currency-symbol":_vm.currencySymbol(),"show-buttons":_vm.showButtons,"plan-to-select":"pro","is-loading":_vm.isLoading,"features":[{text: 'All from Essential'},
                      {text: '<b>Unlimited</b> email finder credits'},
                      {text: '<b>100</b> mobile finder credits /month'},
                      {text: '<b>200</b> SalesNav export credits /day'},
                      {text: 'Dashboard Analytics'}]},on:{"selectPlan":_vm.selectPlan,"change-is-loading":_vm.changeIsLoading}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }