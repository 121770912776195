<template>
  <div style="min-height: 220px">
    <span
      v-for="(feature, index) in features"
      :key="index"
      class="d-block mb-2"
    >
      <feather-icon
        v-if="feature.text !== ''"
        icon="CheckIcon"
        size="18"
        style="color: #1798C1"
      />
      <span
        class="font-small-3 ml-2"
        style="color: #49575B"
        v-html="feature.text"
      />
      <feather-icon
        v-if="feature.tooltip"
        v-b-tooltip.hover.top="feature.tooltip"
        icon="InfoIcon"
        size="16"
        class="ml-1"
        style="color: #889BA0"
      />
    </span>
  </div>
</template>

<script>

import { VBTooltip } from 'bootstrap-vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    features: {
      type: Array,
      default: () => [],
    },
  },
}

</script>
