<template>
  <b-card
    v-b-hover="handleHover"
    align="center"
    :class="[
      hover
        ? 'border-primary border-accent-1'
        : 'border-white',
      'plan-card-h'
    ]"
  >
    <div
      class="b-card-padding"
    >
      <div class="d-flex">
        <h4 class="plan-name">
          {{ upperCasePlanName() }}
        </h4>
        <b-badge
          v-if="planToSelect === 'essential'"
          variant="light-primary"
          class="font-small-1 font-weight-bold popular-badge"
          style=""
          pill
        >
          Popular
        </b-badge>
      </div>

      <div
        class="annual-plan"
        style="border-bottom: 1px solid #E0E5E6"
      >
        <div class="plan-price mt-2">
          <div class="d-flex">
            <span
              class="text-monospace font-weight-bold font-size-50"
              style="color: #15191A"
            >
              {{ currencySymbol }}{{ getPlanPricePerMonth() }}
            </span>
            <div
              style="display: grid; margin-left: 13px; margin-top: 4px"
            >
              <span
                style="font-size: 14px; text-align: initial; font-weight: 400"
                class="text-info text-lighten-2"
              >
                per user
              </span>
              <span
                style="font-size: 14px; text-align: initial; font-weight: 400"
                class="text-info text-lighten-2"
              >
                per month
              </span>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <span
            v-if="getPlanPricePerMonth() == 0"
            class="period-description"
          >
            Free plan
          </span>
          <span
            v-if="period === 'monthly' && getPlanPricePerMonth() > 0"
            class="period-description"
          >
            Billed Monthly
          </span>
          <span
            v-if="period === 'yearly'"
            class="d-flex period-description"
          >
            <span
              v-if=" getPlanPricePerMonth() > 0"
              style="margin-right: 1px"
            >Billed Yearly</span>
            <span
              v-if=" getPlanPricePerMonth() > 0"
              class="ml-1"
            >
              <span
                class="text-info text-lighten-3 mr-1"
                style="text-decoration: line-through"
              >
                {{ currencySymbol }}{{ getPlanPricePerMonth('monthly')*12 }}
              </span>
              {{ currencySymbol }}{{ getPlanPricePerYear() }}
            </span>

            <b-badge
              v-if="discounted && planToSelect !== 'free'"
              class="font-small-1 font-weight-bold special-deal-badge ml-0"
              pill
            >
              <img
                src="@/assets/images/copper-icon.png"
                alt="copper-icon"
                style="width: 12px"
              >
              Special Deal
            </b-badge>
          </span>
          <span
            v-if="discounted && planToSelect === 'basic' && period === 'monthly'"
            class="d-flex period-description"
          >
            <b-badge

              class="font-small-1 font-weight-bold special-deal-badge ml-0"
              pill
            >
              <img
                src="@/assets/images/copper-icon.png"
                alt="copper-icon"
                style="width: 12px"
              >
              Special Deal
            </b-badge>
          </span>
        </div>
      </div>

      <div class="text-left">
        <div class="d-flex justify-content-between">
          <h5
            class="font-small-4 text-left"
            style="margin-bottom: 18px"
          >
            Features
          </h5>
        </div>
        <PricingPlanFeatures :features="features" />
      </div>

      <!-- Buttons -->
      <div
        v-if="showButtons && (plan.selected === planToSelect)"
        class="full-w-btn"
      >
        <b-button
          variant="flat-success"
          disabled
          style=" width: 100%"
          class="mt-4 plan-btn"
          block
        >
          Your current plan
        </b-button>
      </div>
      <div
        v-else-if="showButtons && (plan.selected !== planToSelect)"
        class="full-w-btn"
      >
        <b-button
          variant="primary"
          :disabled="isLoading || planToSelect === 'free'"
          style="width: 100%"
          class="mt-4 plan-btn"
          @click="switchPlanVerification()"
        >
          <b-spinner
            v-if="isLoading && waitForSwitch[planToSelect]"
            class="mx-2"
            small
          />
          <span v-else>Switch to {{ planToSelect }}</span>
        </b-button>
      </div>
    </div>

    <div
      v-if="shouldDisplayChooseButton()"
      class="button-padding"
    >
      <b-button
        v-if="planToSelect === 'free'"
        style="width: 100%; pointer-events: none"
        disabled
      >
        Choose {{ upperCasePlanName() }}
      </b-button>
      <b-button
        v-else
        style="width: 100%"
        :variant="'primary'"
        @click="goToCheckout"
      >
        Choose {{ upperCasePlanName() }}
      </b-button>
    </div>
    <div
      v-if="shouldDisplayAlreadySurfingButton()"
      class="button-padding"
      style="font-size: 16px; color: #1798C1;"
    >
      Already Surfing
    </div>

    <!-- Modals -->
    <CheckoutModal
      ref="checkout"
      :plan="plan.selected"
      :period="period"
      :namespace="plan.namespace"
      :currency="currency"
      :currency-symbol="currencySymbol"
      :tiered="plan.tiered"
      :price="period === 'monthly' ? getPlanPricePerMonth() : getPlanPricePerYear()"
    />

    <AdminRequiredModal ref="modal-admin-required" />
    <VerificationCodeModal
      :ref-id="`switch-plan-modal-verification-${planToSelect}`"
      @submit="switchPlan()"
    />
  </b-card>
</template>

<script>

import constants from '@/constants'
import {
  BCard,
  BButton,
  BSpinner,
  BBadge,
  VBHover,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
import PricingPlanFeatures from '@/views/billing/components/PricingPlanFeatures.vue'
import CheckoutModal from '@/views/billing/components/CheckoutModal.vue'
import AdminRequiredModal from '@/views/billing/components/AdminRequiredModal.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { trackEvent } from '@core/utils/tracking'
import VerificationCodeModal from '@/views/billing/VerificationCodeModal.vue'
import { hasValidVerificationToken, isGodMode } from '@/utils'

export default {
  directives: { 'b-hover': VBHover },
  components: {
    VerificationCodeModal,
    PricingPlanFeatures,
    BCard,
    BButton,
    CheckoutModal,
    AdminRequiredModal,
    BSpinner,
    BBadge,
  },
  props: {
    plan: {
      type: Object,
      default: () => {
      },
    },
    period: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    currencySymbol: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    planToSelect: {
      type: String,
      default: '',
    },
    features: {
      type: Array,
      default: () => [],
    },
    licences: {
      type: Number,
      default: 0,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pricing: constants.PRICING,
      contactMessage: '',
      waitForSwitch: { free: false, essential: false, pro: false },
      hover: false,
      allPlans: ['free', 'essential', 'pro'],
    }
  },
  computed: {
    ...mapState({
      discounted: state => state.api.account.crm === 'COPPER',
      account: state => state.api.account,
      hasOEM: state => !!state.api.account.hasOEM,
    }),
  },
  methods: {
    shouldDisplayChooseButton() {
      if (this.showButtons) return false

      if (this.hasOEM) {
        return this.planToSelect !== 'basic'
      }

      return true
    },
    shouldDisplayAlreadySurfingButton() {
      if (!this.showButtons) return false

      return this.plan.selected === this.planToSelect
    },
    goToCheckout() {
      this.$emit('selectPlan', this.planToSelect)

      if (!this.account.isAdmin) { return this.$refs['modal-admin-required'].show() }
      this.$refs.checkout.showModalByName('modal-licences')
    },
    upperCasePlanName() {
      return this.planToSelect.charAt(0).toUpperCase() + this.planToSelect.slice(1)
    },
    handleHover(isHovered) {
      if (this.planToSelect === 'free') return
      this.hover = isHovered
    },
    getPlanPricePerMonth(period) {
      const price = this.pricing[this.planToSelect][this.currency]
      if (this.discounted && this.hasOEM && this.planToSelect === 'basic') return 0
      if (this.period === 'monthly' || period === 'monthly') return price.monthly
      return this.discounted ? price.yearly.discountedPerMonth : price.yearly.perMonth
    },
    getPlanPricePerYear() {
      const price = this.pricing[this.planToSelect][this.currency]
      return this.discounted ? price.yearly.discountedTotalAnnual : price.yearly.totalAnnual
    },
    switchPlanVerification() {
      if (hasValidVerificationToken() || isGodMode()) {
        this.switchPlan()
        return
      }
      this.$bvModal.show(`switch-plan-modal-verification-${this.planToSelect}`)
    },
    async switchPlan() {
      this.$bvModal.hide(`switch-plan-modal-verification-${this.planToSelect}`)
      this.$bvModal
        .msgBoxConfirm('Please confirm your changes.', {
          title: 'Switch plan',
          size: 'md',
          okVariant: 'primary',
          okTitle: `Switch to ${this.planToSelect.toUpperCase()}`,
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          if (!value) {
            this.waitForSwitch[this.planToSelect] = false
            this.$emit('change-is-loading', false)
            return
          }

          this.$emit('change-is-loading', true)
          if (!value) return
          this.waitForSwitch[this.planToSelect] = true
          try {
            await this.$store.dispatch('api/updateSubscription', {
              Plan: this.planToSelect,
              Frequency: this.period,
              Currency: this.currency,
              Namespace: 'surfe',
              Tiered: this.tiered,
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Surfe plan updated',
                icon: 'SaveIcon',
                variant: 'success',
              },
            })

            await trackEvent({ type: 'dashboard-billing-changed_plan', props: { plan: this.planToSelect.toUpperCase() } })
            setTimeout(async () => {
              await this.$store.dispatch('api/loadAccount')
              await this.$store.dispatch('api/loadCustomer')
              this.selectPlan(this.planToSelect)
              this.waitForSwitch[this.planToSelect] = false
            }, 2000)
          } catch (error) {
            console.error(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            this.waitForSwitch[this.planToSelect] = false
          } finally {
            this.waitForSwitch[this.planToSelect] = false
            this.$emit('change-is-loading', false)
          }
        })
    },
  },
}
</script>

<style scoped>
.card-body {
  padding: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card {
  display: flex;
  align-items: center;
}

.plan-name {
  color: #3A4446;
}

.b-card-padding {
  width: 100%;
  padding: 40px 38px 92px 38px;
}

.popular-badge {
  margin-left: 11px;
  height: 20px;
}

.font-size-50 {
  font-size: 50px;
}

.period-description {
  font-size: 14px;
  margin: 20px  0 30px 0
}

.special-deal-badge {
  margin-left: 10px;
  height: 20px;
  color: #EA75CF;background-color: #FFEBFA;
}

.full-w-btn {
  width: 100%;
  padding: 16px
}

.button-padding {
  position: absolute;
  padding: 0 16px;
  bottom: 16px;
  width: 100%;
}

.plan-card-h {
  height: 600px;
}
</style>
