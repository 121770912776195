<template>
  <div>
    <!-- Admin Required Modal -->
    <b-modal
      id="modal-admin-required"
      ref="modal-admin-required"
      title="Admin Required"
      centered
      hide-footer
      hide-header-close
      no-fade
    >
      <p class="mb-2">
        Please contact your account admin to subscribe.
      </p>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  methods: {
    show() {
      this.$refs['modal-admin-required'].show()
    },
  },
}
</script>
