<template>
  <b-row class="pricing-card">
    <b-col
      v-if="showCurrencySwitch"
      :class="['col-sm-12', windowWidth > 1655 ? 'col-lg-10' : 'col-lg-12']"
      class="mx-auto mb-3"
    >
      <div class="d-flex justify-content-end align-items-center">
        <span class="mr-2">Prices in</span>
        <b-form-select
          v-model="currency"
          style="text-transform: uppercase; max-width: 80px"
          :options="['eur', 'usd']"
          size="sm"
        />
      </div>
    </b-col>
    <b-col
      :class="['mx-auto', windowWidth > 1655 ? 'col-lg-10' : 'col-lg-12']"
    >
      <b-row class="match-height d-flex justify-content-center">
        <!-- FREE -->
        <b-col
          lg="4"
          md="8"
        >
          <PlanCard
            v-if="account.crm !== 'GSHEETS'"
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            :licences="licences"
            plan-to-select="free"
            :features="[{text: 'Embed your CRM in LinkedIn'},
                        {text: 'Add <b>1</b> contact to CRM /day'},
                        {text: '<b>20</b> email finder credits /month'},
                        {text: '<b>5</b> phone finder credits /month'},
                        {text: '<b>30</b> SalesNav export credits', tooltip: 'Your company must own at least one Sales Navigator licence to use this feature'},
                        {text: '<b>1</b> Message template'},
                        {text: 'Deals, Tasks, Notes on LinkedIn'},
                        {text: '<b>10</b> message sync per month'},]"
            :is-loading="isLoading"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
          <PlanCard
            v-else
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            :licences="licences"
            plan-to-select="free"
            :features="[{text: 'Custom extra fields on LinkedIn'},
                        {text: 'Add <b>1</b> contact to CRM /day'},
                        {text: '<b>20</b> email finder credits /month'},
                        {text: '<b>5</b> phone finder credits /month'},
                        {text: '<b>30</b> SalesNav export credits', tooltip: 'Your company must own at least one Sales Navigator licence to use this feature'},
                        {text: '<b>1</b> Message template'},]"
            :is-loading="isLoading"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
        </b-col>

        <!-- ESSENTIAL -->
        <b-col
          lg="4"
          md="8"
        >
          <PlanCard
            v-if="account.crm !== 'GSHEETS'"
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            plan-to-select="essential"
            :features="[{text: 'All from Free'},
                        {text: 'Add <b>unlimited</b> contacts to CRM'},
                        {text: '<b>150</b> email finder credits /month'},
                        {text: '<b>50</b> mobile finder credits /month'},
                        {text: '<b>100</b> SalesNav export credits /day'},
                        {text: '<b>Unlimited</b> message templates'},
                        {text: 'Sync <b>unlimited</b> LI messages to CRM'},
                        {text: 'Sales enablement', tooltip: 'Salesloft and Outreach integrations support'}]"
            :is-loading="isLoading"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
          <PlanCard
            v-else
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            plan-to-select="essential"
            :features="[{text: 'All from Free'},
                        {text: 'Add <b>unlimited</b> Linkedin contacts'},
                        {text: '<b>150</b> email finder credits /month'},
                        {text: '<b>50</b> mobile finder credits /month'},
                        {text: '<b>100</b> SalesNav export credits /day'},
                        {text: '<b>Unlimited</b> message templates'}]"
            :is-loading="isLoading"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
        </b-col>

        <!-- PRO -->
        <b-col
          lg="4"
          md="8"
        >
          <PlanCard
            v-if="account.crm !== 'GSHEETS'"
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            plan-to-select="pro"
            :is-loading="isLoading"
            :features="[{text: 'All from Essential'},
                        {text: '<b>Unlimited</b> email finder credits'},
                        {text: '<b>100</b> mobile finder credits /month'},
                        {text: '<b>200</b> SalesNav export credits /day'},
                        {text: 'Job change alerts'},
                        {text: 'Dashboard Analytics'}]"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
          <PlanCard
            v-else
            :plan="plan"
            :period="period"
            :currency="currency"
            :currency-symbol="currencySymbol()"
            :show-buttons="showButtons"
            plan-to-select="pro"
            :is-loading="isLoading"
            :features="[{text: 'All from Essential'},
                        {text: '<b>Unlimited</b> email finder credits'},
                        {text: '<b>100</b> mobile finder credits /month'},
                        {text: '<b>200</b> SalesNav export credits /day'},
                        {text: 'Dashboard Analytics'}]"
            @selectPlan="selectPlan"
            @change-is-loading="changeIsLoading"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BFormSelect,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import constants from '@/constants'
import { mapState } from 'vuex'
import PlanCard from '@/views/billing/components/PlanCard.vue'

export default {
  components: {
    PlanCard,
    BRow,
    BCol,
    BFormSelect,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    period: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: 'eur',
    },
    showButtons: {
      type: Boolean,
      default: false,
    },
    licences: {
      type: Number,
      default: 0,
    },
    showCurrencySwitch: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      pricing: constants.PRICING,
      windowWidth: window.innerWidth,
      isLoading: false,
      contactMessage: '',
      waitForSwitch: { basic: false, enrich: false },
    }
  },
  computed: {
    ...mapState({
      account: state => state.api.account,
      discounted: state => state.api.account.crm === 'COPPER',
      hasOEM: state => state.api.account.hasOEM,
      namespace: state => state.api.account.namespace,
      tiered: state => state.api.account.tiered,
    }),
  },
  watch: {
    period() {
      this.selectPlan(this.plan.selected)
    },
  },
  mounted() {
    this.selectPlan(this.plan.selected)
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
    },
    selectPlan(p) {
      this.plan.namespace = this.namespace
      this.plan.tiered = this.tiered
      if (this.showButtons) return
      const pricing = this.pricing[p][this.currency]
      this.plan.selected = p
      if (this.hasOEM && (p === 'starter' || p === 'basic')) {
        this.plan.price = 0
      } else if (this.discounted) {
        this.plan.price = this.period === 'yearly'
          ? pricing.yearly.discountedPerMonth
          : pricing.monthly
      } else {
        this.plan.price = this.period === 'yearly' ? pricing.yearly.perMonth : pricing.monthly
      }
      if (this.hasOEM && (p === 'starter' || p === 'basic')) this.plan.price = 0
    },
    currencySymbol() {
      return this.currency === 'eur' ? '€' : '$'
    },
    changeIsLoading(loading) {
      this.isLoading = loading
    },
    async switchPlan(p, name) {
      this.$bvModal
        .msgBoxConfirm('Please confirm your changes.', {
          title: 'Switch plan',
          size: 'sm',
          okVariant: 'primary',
          okTitle: `Switch to ${name.toUpperCase()}`,
          cancelTitle: 'Cancel',
          cancelVariant: 'outline-primary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(async value => {
          if (!value) {
            this.waitForSwitch[p] = false
            return
          }

          this.waitForSwitch[p] = true
          try {
            await this.$store.dispatch('api/updateSubscription', {
              Plan: p,
              Frequency: this.period,
              Currency: this.currency,
              Namespace: 'surfe',
              Tiered: this.tiered,
            })

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Surfe plan updated',
                icon: 'SaveIcon',
                variant: 'success',
              },
            })

            setTimeout(async () => {
              await this.$store.dispatch('api/loadAccount')
              await this.$store.dispatch('api/loadCustomer')
              this.selectPlan(p)
              this.waitForSwitch[p] = false
            }, 2000)
          } catch (error) {
            console.error(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
            this.waitForSwitch[p] = false
          }
        })
    },
  },
}
</script>

<style scoped>
.premium-btn {
  display: flex;
  align-items: center;
  background-color: rgba(40, 199, 111, 0.15);
}

.strikediag {
  background: linear-gradient(
    to left top,
    transparent 47.75%,
    currentColor 49.5%,
    currentColor 50.5%,
    transparent 52.25%
  );
}

.list-group.list-group-circle .list-group-item:after {
  top: 0.4rem !important;
}
</style>
