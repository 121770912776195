const PRICING = {
  // FREE
  free: {
    eur: {
      monthly: 0,
      yearly: {
        perMonth: 0,
        totalAnnual: 0 * 12,
        discountedPerMonth: 0,
        discountedTotalAnnual: 0 * 12,
        discountDisplayValue: 0,
      },
    },
    usd: {
      monthly: 0,
      yearly: {
        perMonth: 0,
        totalAnnual: 0 * 12,
        discountedPerMonth: 0,
        discountedTotalAnnual: 0 * 12,
        discountDisplayValue: 0,
      },
    },
  },
  // ESSENTIAL
  essential: {
    eur: {
      monthly: 39,
      yearly: {
        perMonth: 29,
        totalAnnual: 29 * 12,
        discountedPerMonth: 23,
        discountedTotalAnnual: 23 * 12,
        discountDisplayValue: 25,
      },
    },
    usd: {
      monthly: 39,
      yearly: {
        perMonth: 29,
        totalAnnual: 29 * 12,
        discountedPerMonth: 23,
        discountedTotalAnnual: 23 * 12,
        discountDisplayValue: 25,
      },
    },
  },
  // PRO
  pro: {
    eur: {
      monthly: 79,
      yearly: {
        perMonth: 59,
        totalAnnual: 59 * 12,
        discountedPerMonth: 49,
        discountedTotalAnnual: 49 * 12,
        discountDisplayValue: 25,
      },
    },
    usd: {
      monthly: 79,
      yearly: {
        perMonth: 59,
        totalAnnual: 59 * 12,
        discountedPerMonth: 49,
        discountedTotalAnnual: 49 * 12,
        discountDisplayValue: 25,
      },
    },
  },
  // ============ LEGACY ============
  // Basic
  basic: {
    eur: {
      monthly: 27,
      yearly: {
        perMonth: 23,
        totalAnnual: 23 * 12,
        discountedPerMonth: 10,
        discountedTotalAnnual: 10 * 12,
        discountDisplayValue: 15,
      },
    },
    usd: {
      monthly: 27,
      yearly: {
        perMonth: 23,
        totalAnnual: 23 * 12,
        discountedPerMonth: 10,
        discountedTotalAnnual: 10 * 12,
        discountDisplayValue: 15,
      },
    },
  },
  // ENRICH (discontinued)
  enrich: {
    eur: {
      monthly: 35,
      yearly: {
        perMonth: 27,
        totalAnnual: 324,
        discountedPerMonth: 20,
        discountedTotalAnnual: 240,
      },
    },
    usd: {
      monthly: 40,
      yearly: {
        perMonth: 31,
        totalAnnual: 372,
        discountedPerMonth: 23,
        discountedTotalAnnual: 276,
      },
    },
  },
  // PROFESSIONAL
  professional: {
    eur: {
      monthly: 35,
      yearly: {
        perMonth: 29,
        totalAnnual: 29 * 12,
        discountedPerMonth: 23,
        discountedTotalAnnual: 23 * 12,
        discountDisplayValue: 20,
      },
    },
    usd: {
      monthly: 39,
      yearly: {
        perMonth: 29,
        totalAnnual: 29 * 12,
        discountedPerMonth: 23,
        discountedTotalAnnual: 23 * 12,
        discountDisplayValue: 25,
      },
    },
  },
  // BUSINESS
  business: {
    eur: {
      monthly: 69,
      yearly: {
        perMonth: 59,
        totalAnnual: 59 * 12,
        discountedPerMonth: 49,
        discountedTotalAnnual: 49 * 12,
        discountDisplayValue: 15,
      },
    },
    usd: {
      monthly: 79,
      yearly: {
        perMonth: 59,
        totalAnnual: 59 * 12,
        discountedPerMonth: 49,
        discountedTotalAnnual: 49 * 12,
        discountDisplayValue: 25,
      },
    },
  },
}
export default {
  PRICING,
}
